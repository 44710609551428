import _core from "./core";
import _c from "./languages/1c";
import _abnf from "./languages/abnf";
import _accesslog from "./languages/accesslog";
import _actionscript from "./languages/actionscript";
import _ada from "./languages/ada";
import _angelscript from "./languages/angelscript";
import _apache from "./languages/apache";
import _applescript from "./languages/applescript";
import _arcade from "./languages/arcade";
import _arduino from "./languages/arduino";
import _armasm from "./languages/armasm";
import _xml from "./languages/xml";
import _asciidoc from "./languages/asciidoc";
import _aspectj from "./languages/aspectj";
import _autohotkey from "./languages/autohotkey";
import _autoit from "./languages/autoit";
import _avrasm from "./languages/avrasm";
import _awk from "./languages/awk";
import _axapta from "./languages/axapta";
import _bash from "./languages/bash";
import _basic from "./languages/basic";
import _bnf from "./languages/bnf";
import _brainfuck from "./languages/brainfuck";
import _c2 from "./languages/c";
import _cal from "./languages/cal";
import _capnproto from "./languages/capnproto";
import _ceylon from "./languages/ceylon";
import _clean from "./languages/clean";
import _clojure from "./languages/clojure";
import _clojureRepl from "./languages/clojure-repl";
import _cmake from "./languages/cmake";
import _coffeescript from "./languages/coffeescript";
import _coq from "./languages/coq";
import _cos from "./languages/cos";
import _cpp from "./languages/cpp";
import _crmsh from "./languages/crmsh";
import _crystal from "./languages/crystal";
import _csharp from "./languages/csharp";
import _csp from "./languages/csp";
import _css from "./languages/css";
import _d from "./languages/d";
import _markdown from "./languages/markdown";
import _dart from "./languages/dart";
import _delphi from "./languages/delphi";
import _diff from "./languages/diff";
import _django from "./languages/django";
import _dns from "./languages/dns";
import _dockerfile from "./languages/dockerfile";
import _dos from "./languages/dos";
import _dsconfig from "./languages/dsconfig";
import _dts from "./languages/dts";
import _dust from "./languages/dust";
import _ebnf from "./languages/ebnf";
import _elixir from "./languages/elixir";
import _elm from "./languages/elm";
import _ruby from "./languages/ruby";
import _erb from "./languages/erb";
import _erlangRepl from "./languages/erlang-repl";
import _erlang from "./languages/erlang";
import _excel from "./languages/excel";
import _fix from "./languages/fix";
import _flix from "./languages/flix";
import _fortran from "./languages/fortran";
import _fsharp from "./languages/fsharp";
import _gams from "./languages/gams";
import _gauss from "./languages/gauss";
import _gcode from "./languages/gcode";
import _gherkin from "./languages/gherkin";
import _glsl from "./languages/glsl";
import _gml from "./languages/gml";
import _go from "./languages/go";
import _golo from "./languages/golo";
import _gradle from "./languages/gradle";
import _graphql from "./languages/graphql";
import _groovy from "./languages/groovy";
import _haml from "./languages/haml";
import _handlebars from "./languages/handlebars";
import _haskell from "./languages/haskell";
import _haxe from "./languages/haxe";
import _hsp from "./languages/hsp";
import _http from "./languages/http";
import _hy from "./languages/hy";
import _inform from "./languages/inform7";
import _ini from "./languages/ini";
import _irpf from "./languages/irpf90";
import _isbl from "./languages/isbl";
import _java from "./languages/java";
import _javascript from "./languages/javascript";
import _jbossCli from "./languages/jboss-cli";
import _json from "./languages/json";
import _julia from "./languages/julia";
import _juliaRepl from "./languages/julia-repl";
import _kotlin from "./languages/kotlin";
import _lasso from "./languages/lasso";
import _latex from "./languages/latex";
import _ldif from "./languages/ldif";
import _leaf from "./languages/leaf";
import _less from "./languages/less";
import _lisp from "./languages/lisp";
import _livecodeserver from "./languages/livecodeserver";
import _livescript from "./languages/livescript";
import _llvm from "./languages/llvm";
import _lsl from "./languages/lsl";
import _lua from "./languages/lua";
import _makefile from "./languages/makefile";
import _mathematica from "./languages/mathematica";
import _matlab from "./languages/matlab";
import _maxima from "./languages/maxima";
import _mel from "./languages/mel";
import _mercury from "./languages/mercury";
import _mipsasm from "./languages/mipsasm";
import _mizar from "./languages/mizar";
import _perl from "./languages/perl";
import _mojolicious from "./languages/mojolicious";
import _monkey from "./languages/monkey";
import _moonscript from "./languages/moonscript";
import _n1ql from "./languages/n1ql";
import _nestedtext from "./languages/nestedtext";
import _nginx from "./languages/nginx";
import _nim from "./languages/nim";
import _nix from "./languages/nix";
import _nodeRepl from "./languages/node-repl";
import _nsis from "./languages/nsis";
import _objectivec from "./languages/objectivec";
import _ocaml from "./languages/ocaml";
import _openscad from "./languages/openscad";
import _oxygene from "./languages/oxygene";
import _parser from "./languages/parser3";
import _pf from "./languages/pf";
import _pgsql from "./languages/pgsql";
import _php from "./languages/php";
import _phpTemplate from "./languages/php-template";
import _plaintext from "./languages/plaintext";
import _pony from "./languages/pony";
import _powershell from "./languages/powershell";
import _processing from "./languages/processing";
import _profile from "./languages/profile";
import _prolog from "./languages/prolog";
import _properties from "./languages/properties";
import _protobuf from "./languages/protobuf";
import _puppet from "./languages/puppet";
import _purebasic from "./languages/purebasic";
import _python from "./languages/python";
import _pythonRepl from "./languages/python-repl";
import _q from "./languages/q";
import _qml from "./languages/qml";
import _r from "./languages/r";
import _reasonml from "./languages/reasonml";
import _rib from "./languages/rib";
import _roboconf from "./languages/roboconf";
import _routeros from "./languages/routeros";
import _rsl from "./languages/rsl";
import _ruleslanguage from "./languages/ruleslanguage";
import _rust from "./languages/rust";
import _sas from "./languages/sas";
import _scala from "./languages/scala";
import _scheme from "./languages/scheme";
import _scilab from "./languages/scilab";
import _scss from "./languages/scss";
import _shell from "./languages/shell";
import _smali from "./languages/smali";
import _smalltalk from "./languages/smalltalk";
import _sml from "./languages/sml";
import _sqf from "./languages/sqf";
import _sql from "./languages/sql";
import _stan from "./languages/stan";
import _stata from "./languages/stata";
import _step from "./languages/step21";
import _stylus from "./languages/stylus";
import _subunit from "./languages/subunit";
import _swift from "./languages/swift";
import _taggerscript from "./languages/taggerscript";
import _yaml from "./languages/yaml";
import _tap from "./languages/tap";
import _tcl from "./languages/tcl";
import _thrift from "./languages/thrift";
import _tp from "./languages/tp";
import _twig from "./languages/twig";
import _typescript from "./languages/typescript";
import _vala from "./languages/vala";
import _vbnet from "./languages/vbnet";
import _vbscript from "./languages/vbscript";
import _vbscriptHtml from "./languages/vbscript-html";
import _verilog from "./languages/verilog";
import _vhdl from "./languages/vhdl";
import _vim from "./languages/vim";
import _wasm from "./languages/wasm";
import _wren from "./languages/wren";
import _x86asm from "./languages/x86asm";
import _xl from "./languages/xl";
import _xquery from "./languages/xquery";
import _zephir from "./languages/zephir";
var exports = {};
var hljs = _core;
hljs.registerLanguage("1c", _c);
hljs.registerLanguage("abnf", _abnf);
hljs.registerLanguage("accesslog", _accesslog);
hljs.registerLanguage("actionscript", _actionscript);
hljs.registerLanguage("ada", _ada);
hljs.registerLanguage("angelscript", _angelscript);
hljs.registerLanguage("apache", _apache);
hljs.registerLanguage("applescript", _applescript);
hljs.registerLanguage("arcade", _arcade);
hljs.registerLanguage("arduino", _arduino);
hljs.registerLanguage("armasm", _armasm);
hljs.registerLanguage("xml", _xml);
hljs.registerLanguage("asciidoc", _asciidoc);
hljs.registerLanguage("aspectj", _aspectj);
hljs.registerLanguage("autohotkey", _autohotkey);
hljs.registerLanguage("autoit", _autoit);
hljs.registerLanguage("avrasm", _avrasm);
hljs.registerLanguage("awk", _awk);
hljs.registerLanguage("axapta", _axapta);
hljs.registerLanguage("bash", _bash);
hljs.registerLanguage("basic", _basic);
hljs.registerLanguage("bnf", _bnf);
hljs.registerLanguage("brainfuck", _brainfuck);
hljs.registerLanguage("c", _c2);
hljs.registerLanguage("cal", _cal);
hljs.registerLanguage("capnproto", _capnproto);
hljs.registerLanguage("ceylon", _ceylon);
hljs.registerLanguage("clean", _clean);
hljs.registerLanguage("clojure", _clojure);
hljs.registerLanguage("clojure-repl", _clojureRepl);
hljs.registerLanguage("cmake", _cmake);
hljs.registerLanguage("coffeescript", _coffeescript);
hljs.registerLanguage("coq", _coq);
hljs.registerLanguage("cos", _cos);
hljs.registerLanguage("cpp", _cpp);
hljs.registerLanguage("crmsh", _crmsh);
hljs.registerLanguage("crystal", _crystal);
hljs.registerLanguage("csharp", _csharp);
hljs.registerLanguage("csp", _csp);
hljs.registerLanguage("css", _css);
hljs.registerLanguage("d", _d);
hljs.registerLanguage("markdown", _markdown);
hljs.registerLanguage("dart", _dart);
hljs.registerLanguage("delphi", _delphi);
hljs.registerLanguage("diff", _diff);
hljs.registerLanguage("django", _django);
hljs.registerLanguage("dns", _dns);
hljs.registerLanguage("dockerfile", _dockerfile);
hljs.registerLanguage("dos", _dos);
hljs.registerLanguage("dsconfig", _dsconfig);
hljs.registerLanguage("dts", _dts);
hljs.registerLanguage("dust", _dust);
hljs.registerLanguage("ebnf", _ebnf);
hljs.registerLanguage("elixir", _elixir);
hljs.registerLanguage("elm", _elm);
hljs.registerLanguage("ruby", _ruby);
hljs.registerLanguage("erb", _erb);
hljs.registerLanguage("erlang-repl", _erlangRepl);
hljs.registerLanguage("erlang", _erlang);
hljs.registerLanguage("excel", _excel);
hljs.registerLanguage("fix", _fix);
hljs.registerLanguage("flix", _flix);
hljs.registerLanguage("fortran", _fortran);
hljs.registerLanguage("fsharp", _fsharp);
hljs.registerLanguage("gams", _gams);
hljs.registerLanguage("gauss", _gauss);
hljs.registerLanguage("gcode", _gcode);
hljs.registerLanguage("gherkin", _gherkin);
hljs.registerLanguage("glsl", _glsl);
hljs.registerLanguage("gml", _gml);
hljs.registerLanguage("go", _go);
hljs.registerLanguage("golo", _golo);
hljs.registerLanguage("gradle", _gradle);
hljs.registerLanguage("graphql", _graphql);
hljs.registerLanguage("groovy", _groovy);
hljs.registerLanguage("haml", _haml);
hljs.registerLanguage("handlebars", _handlebars);
hljs.registerLanguage("haskell", _haskell);
hljs.registerLanguage("haxe", _haxe);
hljs.registerLanguage("hsp", _hsp);
hljs.registerLanguage("http", _http);
hljs.registerLanguage("hy", _hy);
hljs.registerLanguage("inform7", _inform);
hljs.registerLanguage("ini", _ini);
hljs.registerLanguage("irpf90", _irpf);
hljs.registerLanguage("isbl", _isbl);
hljs.registerLanguage("java", _java);
hljs.registerLanguage("javascript", _javascript);
hljs.registerLanguage("jboss-cli", _jbossCli);
hljs.registerLanguage("json", _json);
hljs.registerLanguage("julia", _julia);
hljs.registerLanguage("julia-repl", _juliaRepl);
hljs.registerLanguage("kotlin", _kotlin);
hljs.registerLanguage("lasso", _lasso);
hljs.registerLanguage("latex", _latex);
hljs.registerLanguage("ldif", _ldif);
hljs.registerLanguage("leaf", _leaf);
hljs.registerLanguage("less", _less);
hljs.registerLanguage("lisp", _lisp);
hljs.registerLanguage("livecodeserver", _livecodeserver);
hljs.registerLanguage("livescript", _livescript);
hljs.registerLanguage("llvm", _llvm);
hljs.registerLanguage("lsl", _lsl);
hljs.registerLanguage("lua", _lua);
hljs.registerLanguage("makefile", _makefile);
hljs.registerLanguage("mathematica", _mathematica);
hljs.registerLanguage("matlab", _matlab);
hljs.registerLanguage("maxima", _maxima);
hljs.registerLanguage("mel", _mel);
hljs.registerLanguage("mercury", _mercury);
hljs.registerLanguage("mipsasm", _mipsasm);
hljs.registerLanguage("mizar", _mizar);
hljs.registerLanguage("perl", _perl);
hljs.registerLanguage("mojolicious", _mojolicious);
hljs.registerLanguage("monkey", _monkey);
hljs.registerLanguage("moonscript", _moonscript);
hljs.registerLanguage("n1ql", _n1ql);
hljs.registerLanguage("nestedtext", _nestedtext);
hljs.registerLanguage("nginx", _nginx);
hljs.registerLanguage("nim", _nim);
hljs.registerLanguage("nix", _nix);
hljs.registerLanguage("node-repl", _nodeRepl);
hljs.registerLanguage("nsis", _nsis);
hljs.registerLanguage("objectivec", _objectivec);
hljs.registerLanguage("ocaml", _ocaml);
hljs.registerLanguage("openscad", _openscad);
hljs.registerLanguage("oxygene", _oxygene);
hljs.registerLanguage("parser3", _parser);
hljs.registerLanguage("pf", _pf);
hljs.registerLanguage("pgsql", _pgsql);
hljs.registerLanguage("php", _php);
hljs.registerLanguage("php-template", _phpTemplate);
hljs.registerLanguage("plaintext", _plaintext);
hljs.registerLanguage("pony", _pony);
hljs.registerLanguage("powershell", _powershell);
hljs.registerLanguage("processing", _processing);
hljs.registerLanguage("profile", _profile);
hljs.registerLanguage("prolog", _prolog);
hljs.registerLanguage("properties", _properties);
hljs.registerLanguage("protobuf", _protobuf);
hljs.registerLanguage("puppet", _puppet);
hljs.registerLanguage("purebasic", _purebasic);
hljs.registerLanguage("python", _python);
hljs.registerLanguage("python-repl", _pythonRepl);
hljs.registerLanguage("q", _q);
hljs.registerLanguage("qml", _qml);
hljs.registerLanguage("r", _r);
hljs.registerLanguage("reasonml", _reasonml);
hljs.registerLanguage("rib", _rib);
hljs.registerLanguage("roboconf", _roboconf);
hljs.registerLanguage("routeros", _routeros);
hljs.registerLanguage("rsl", _rsl);
hljs.registerLanguage("ruleslanguage", _ruleslanguage);
hljs.registerLanguage("rust", _rust);
hljs.registerLanguage("sas", _sas);
hljs.registerLanguage("scala", _scala);
hljs.registerLanguage("scheme", _scheme);
hljs.registerLanguage("scilab", _scilab);
hljs.registerLanguage("scss", _scss);
hljs.registerLanguage("shell", _shell);
hljs.registerLanguage("smali", _smali);
hljs.registerLanguage("smalltalk", _smalltalk);
hljs.registerLanguage("sml", _sml);
hljs.registerLanguage("sqf", _sqf);
hljs.registerLanguage("sql", _sql);
hljs.registerLanguage("stan", _stan);
hljs.registerLanguage("stata", _stata);
hljs.registerLanguage("step21", _step);
hljs.registerLanguage("stylus", _stylus);
hljs.registerLanguage("subunit", _subunit);
hljs.registerLanguage("swift", _swift);
hljs.registerLanguage("taggerscript", _taggerscript);
hljs.registerLanguage("yaml", _yaml);
hljs.registerLanguage("tap", _tap);
hljs.registerLanguage("tcl", _tcl);
hljs.registerLanguage("thrift", _thrift);
hljs.registerLanguage("tp", _tp);
hljs.registerLanguage("twig", _twig);
hljs.registerLanguage("typescript", _typescript);
hljs.registerLanguage("vala", _vala);
hljs.registerLanguage("vbnet", _vbnet);
hljs.registerLanguage("vbscript", _vbscript);
hljs.registerLanguage("vbscript-html", _vbscriptHtml);
hljs.registerLanguage("verilog", _verilog);
hljs.registerLanguage("vhdl", _vhdl);
hljs.registerLanguage("vim", _vim);
hljs.registerLanguage("wasm", _wasm);
hljs.registerLanguage("wren", _wren);
hljs.registerLanguage("x86asm", _x86asm);
hljs.registerLanguage("xl", _xl);
hljs.registerLanguage("xquery", _xquery);
hljs.registerLanguage("zephir", _zephir);
hljs.HighlightJS = hljs;
hljs.default = hljs;
exports = hljs;
export default exports;